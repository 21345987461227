import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import Article from "../components/sections/Article"
import { Main } from "../components/sections/Content"
import paper from "../images/backgrounds/footerBackground.jpg"
import styled from "@emotion/styled"

const MainPost = styled(Main)`
  background-image: url(${paper});
`

const BlogPostTemplate = ({ data: { markdownRemark: post } }) => {
  return (
    <Layout>
      <MainPost>
        <Article post={post} />
      </MainPost>
    </Layout>
  )
}

export const Head = ({ data: { markdownRemark: post } }) => {
  const titleSeo = "Wildermyth OST - " + post.frontmatter.title
  return (
    <Seo
      title={titleSeo}
      description={post.frontmatter.description || post.excerpt}
    />
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($id: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
      }
    }
  }
`
